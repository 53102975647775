body {
  margin: 0px auto;
  background-color: black;
  color: white;
}
header {
  background-color: transparent;
  padding: 10px 0px 0px 10px;
}

footer span {
  color: slategray;
  font-size: 10px;
  float: right;
}

h1 {
  font-size: 16px;
  color: white;
  margin: 0px;
}

nav {
  margin: 0px 0px 0px 0px;
  padding: 10px 10px 10px 10px;
  background-color: GhostWhite;
  overflow: hidden;
}

.place {
  margin: 0px 10px 0px 0px;
  background-color: GhostWhite;
  color: black;
  float: left;
}
.place a {
  color: black;
  text-decoration: none;
  white-space: nowrap;
}
.place a:hover {
  color: red;
}

.eventsTable {
  padding: 10px 10px 10px 10px;
  clear: left;
}

.colPlaceShow {
  width: 170px;
  text-align: left;
  vertical-align: top;
}

.colPlaceShow a {
  color: white;
  text-decoration: underline;
}
.colPlaceShow a:hover {
  color: red;
}

.colPlaceHide {
  display: none;
}

.colDate {
  text-align: left;
  width: 220px;
  vertical-align: top;
}

.colEvent {
  text-align: left;
  vertical-align: top;
  height: 20px;
}

.colEvent a {
  color: white;
  text-decoration: underline;
}
.colEvent a:hover {
  color: red;
}

.placeSelect {
  margin: 10px 10px 0px 10px;
  width: 40%;
  float: left;
  color: #484848;
}

.placeSelect .Select-placeholder {
  font-weight: 200;
  font-size: 18px;
  line-height: 24px;
  color: #757575;

  padding: 12px 15px;
}

.placeSelect .Select-value {
  background: #99ede6;
  border-color: #99ede6;
  border-radius: 3px;
  color: #007a87;

  padding: 4px 8px 4px 0px;
  white-space: nowrap;
  overflow: hidden;

  margin-top: 2px;
  margin-bottom: 2px;
}

.placeSelect .Select-control {
  border-radius: 0px;
  border: 0px;
  padding: 8px;
}

.DateRangePicker {
  margin: 10px 10px 0px 10px;
}

.DisplayByPlaceSwitch {
  position: relative !important;
  display: inline-block !important;
  margin: 10px;
}

.DisplayByPlaceSwitch span {
  color: white !important;
  font-family: serif !important;
  font-weight: 200;
  font-size: 18px;
  line-height: 24px;
}

span[class*="theme--off"] {
  background: rgba(255, 255, 255, 0.2);
}

span[class*="theme--on"] + span[class*="theme--text"] {
  color: rgb(63, 81, 181) !important;
}

.placeTitle {
  color: rgb(63, 81, 181) !important;
  font-size: 18px;
}

/*==================
 RESPONSIVE DESIGN
====================*/

@media (max-width: 1199px) {
}

@media (max-width: 991px) {
}

@media (max-width: 767px) {
}

@media (max-width: 640px) {
}

@media (max-width: 480px) {
  .colDate {
    width: 100px;
  }
  .colPlaceShow {
    width: 100px;
  }
  .placeSelect {
    width: 80%;
  }
}

@media (max-width: 380px) {
}

@media (max-width: 380px) {
}

@media (max-width: 320px) {
}
